@use '@angular/material' as mat;
@mixin itf-player-list-theme($theme) {

  $primary: map-get($theme, primary);
  $error: map-get($theme, error);
  $secondary: map-get($theme, secondary);
  $background: map-get($theme, background);
  $foreground: map-get($theme, on-surface);

  .player-list {
    padding: 8px;
  }

  .player-list-item {
    &:hover, &:active, &:focus {
      background-color: mat.get-theme-color($theme, primary, 90);
    }

    &.selected {
      background-color: mat.get-theme-color($theme, primary, 80);
    }

  }
  .candidate {
    background-color: mat.get-theme-color($theme, primary, 80);
    padding: 7px!important;
  }

  .instruction {
    background-color: mat.get-theme-color($theme, primary, 90);
    max-width: 500px;
  }
}
