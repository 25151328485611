@use '@angular/material' as mat;
@use './app/external-tournaments/external-player-manager/external-player-manager.component.theme' as externalPlayerManagerComponent;

@include mat.core();

$primary-color: mat.$azure-palette;

$tc-tools-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $primary-color,
    tertiary: mat.$blue-palette,
  ),
  typography: (
    brand-family: 'Roboto',
    bold-weight: 900
  ),
  density: (
    scale:0,
  )
));

@import "flexbox_layout";

:root {
  @include mat.all-component-themes($tc-tools-theme);
  @include mat.all-component-typographies($tc-tools-theme);
  @include mat.typography-hierarchy($tc-tools-theme);
  @include externalPlayerManagerComponent.itf-player-list-theme($tc-tools-theme);
}

.app-width{
  max-width: 1400px;
}

.app-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 8px;
  margin: 5px;
  min-height: 500px;
}

.small-tool {
  width: 600px;
  padding: 10px;
}

.medium-tool {
  width: 800px;
  padding: 10px;
}

.large-tool {
  min-width: 800px;
  padding: 10px;
}

.extra-large-tool {
  min-width: 1400px;
  padding: 10px;
}

.vertical-spacer-medium {
  height: 20px;
}

.vertical-spacer-small {
  height: 10px;
}

.pad-10 {
  padding: 10px;
}

.mat-toolbar {
  background-color: mat.get-theme-color($tc-tools-theme, primary, 90) !important;
  color: mat.get-theme-color($tc-tools-theme, primary, 10) !important;
}
